<template>
  <div class="mt-4"
  >
    <el-upload
        :limit="10"
        action=""
        :accept="is_image ? 'image/jpeg,image/gif,image/png' : 'image/jpeg,image/gif,image/png,video/mp4,video/x-m4v/mov'"
        list-type="picture-card"
        :file-list="fileList"
        :on-change="handleChange"
        :on-preview="handlePictureCardPreview"
        :on-remove="handleRemove"
        :auto-upload="false"
        :onExceed="onExceed"
        :showFileList="true"
        :disabled="isDisable"
    >
      <div v-if="checkIsVideo(file)" slot-scope="{file}" slot="file">
        <video width="100%" slot="file" controls="controls">
          <source :src="file.url" type="video/mp4"/>
        </video>
        <span class="el-upload-list__item-actions">
                    <span class="el-upload-list__item-preview">
                        <i class="el-icon-zoom-in" @click="handlePictureCardPreview(file)"></i>
                    </span>
                    <span class="el-upload-list__item-delete" v-if="!isDisable">
                        <i class="el-icon-delete" @click="handleRemoveVideo(file)"></i>
                    </span>
                </span>
      </div>
      <i class="el-icon-upload"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" v-if="previewVideo">
      <video width="100%" controls="controls" autoplay>
        <source :src="dialogImageUrl" type="video/mp4"/>
      </video>
    </el-dialog>
    <el-dialog :visible.sync="dialogVisible" v-else>
      <img width="100%" :src="dialogImageUrl" alt="">
    </el-dialog>
  </div>
</template>
<script>
import Swal from "sweetalert2";

export default {
  name: "UploadVideo",
  props: {
    fileListProp: {
      type: Array,
      default: () => {
        return [];
      }
    },
    isDisable: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    disableUpload: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
    mediaIds: {
      type: Array,
      default: () => {
        return [];
      }
    },

    is_limited: {
      type: Boolean,
      default: () => {
        return false;
      }
    },

    is_image: {
      type: Boolean,
      default: () => {
        return false;
      }
    },
  },
  data() {
    return {
      imageUrl: "",
      dialogImageUrl: '',
      dialogVisible: false,
      fileList: [],
      rightData: {},
      stt: 0,
      previewVideo: false,
      file_overload: false,
      limit_quantity_video: 1,
      limit_quantity_image: 5,
      count_videos: 0,
      count_images: 0
    }
  },
  watch: {
    fileListProp() {
      this.handleMedia();
    },
    fileList() {
      this.emitPushData();
    }
  },
  mounted() {
    this.disableUploadElement();
    this.handleMedia();
  },
  updated() {
    if (this.file_overload) {
      this.file_overload = false;
      this.removeHtml();
      this.fileList.splice(this.fileList.length - 1, 1);
    }
  },
  methods: {
    disableUploadElement() {
      let elements = document.querySelectorAll(".only-show .el-upload--picture-card");
      if (this.disableUpload) {
        for (let i = 0; i < elements.length; i++) {
          let item = elements[i];
          item.classList.add("disable");
        }
      }

    },
    handleMedia() {
      this.fileList = [];
      if (this.fileListProp.length) {
        this.fileListProp.map((value) => {
          this.convertCndToFile(value);
        })
      }
    },
    convertCndToFile(value) {
      let file = new File(["foo"], value.url, {
        type: value.mime_type
      });
      let data = {
        name: '',
        percentage: 0,
        raw: file,
        status: "ready",
        uid: value.uid,
        url: value.url,
        id: value.id
      };
      this.fileList.push(data);
    },
    handleChange(file) {
      this.countFile(file);
      let checkVideo = this.checkIsVideo(file);
      let verifyFile = 0;
      if (checkVideo) {
        verifyFile = file.size / 1024 / 1024 < 30;
      } else {
        verifyFile = file.size / 1024 / 1024 < 10;
      }

      if(this.is_limited) {
        verifyFile = true;
      }

      if (!verifyFile) {
        this.file_overload = true;
        let textError = '';
        if (checkVideo) {
          textError = 'Chèn tệp thất bại do vượt quá dung lượng cho phép. Video tối đa 30MB';
        } else {
          textError = 'Chèn tệp thất bại do vượt quá dung lượng cho phép. Ảnh tối đa 10Mb';
        }
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: textError,
          showConfirmButton: false,
          timer: 2500
        });
      }

      let count = this.fileList.filter((value) => {
        return value.uid == file.uid
      });

      if (count.length != 0) {
        return false;
      }

      this.fileList.push(file);
      this.stt++;
    },
    countFile(file) {
      this.count_images = 0;
      this.count_videos = 0;
      let checkVideoAdd = this.checkIsVideo(file);
      for (let i = 0; i < this.fileList.length; i++) {
        let checkVideo = this.checkIsVideo(this.fileList[i]);
        if (checkVideo) {
          this.count_videos++;
        } else {
          this.count_images++;
        }
      }
      let textError = '';
      if (checkVideoAdd) {
        if (this.count_videos >= this.limit_quantity_video) {
          this.file_overload = true;
          textError = 'Chèn tệp thất bại do vượt quá số lượng cho phép. Video tối đa ' + this.limit_quantity_video + ' file';
        }
      } else {
        if (this.count_images >= this.limit_quantity_image) {
          this.file_overload = true;
          textError = 'Chèn tệp thất bại do vượt quá số lượng cho phép. Ảnh tối đa ' + this.limit_quantity_image + ' file';
        }
      }
      if (textError) {
        Swal.fire({
          position: 'center',
          icon: 'error',
          title: textError,
          showConfirmButton: false,
          timer: 2500
        });
      }
    },
    removeHtml() {
      let ul = document.querySelector('.el-upload-list');
      if (ul.lastChild) {
        ul.removeChild(ul.lastChild);
      }
    },
    handleRemove(file, fileList) {
      this.fileList = fileList;
      this.removeInMedia(file);
    },
    handleRemoveVideo(file) {
      this.removeInFileList(file);
      this.removeInMedia(file);
    },
    // Xóa ảnh trong file list
    removeInFileList(file) {
      let index = _.findIndex(this.fileList, (value) => {
        return value.uid === file.uid;
      });
      if (index !== -1) {
        this.fileList.splice(index, 1);
      }
    },
    // Xóa id media lấy từ serve
    removeInMedia(file) {
      let indexMedia = _.findIndex(this.mediaIds, (value) => {
        return value === file.id;
      });
      if (indexMedia !== -1) {
        this.mediaIds.splice(indexMedia, 1);
      }
      this.$emit('spliceMediaId', this.mediaIds);
    },
    handlePictureCardPreview(file) {
      this.previewVideo = this.checkIsVideo(file);
      this.dialogImageUrl = file.url;
      this.dialogVisible = true;
    },
    checkIsVideo(file) {
      if(file){
        let type = file.raw.type;
        return ['video/quicktime', 'video/mp4'].includes(type);
      }
    },
    emitPushData() {
      this.$emit('emitUpdateFile', this.fileList);
    },
    onExceed(file) {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: `Tối đa chỉ được ${this.limit_quantity_image} ảnh và ${this.limit_quantity_video} video`,
        showConfirmButton: false,
        timer: 2500
      })
    }
  }
}
</script>

<style scoped>
.el-upload-list__item video {
  width: auto;
}
</style>
<style>
.disable {
  display: none;
}
</style>