<template>
  <div>
    <button class="btn btn-success font-weight-bold btn-sm"
            v-b-modal="`modal-create-ticket`">
      <i class="fas fa-ticket-alt"></i>
      Tạo mới
    </button>
    <b-modal
        @ok="handleOk"
        centered id="modal-create-ticket"
        size="lg"
        title="Tạo ticket"
        ok-title="Lưu"
        cancel-title="Hủy"
        :ok-disabled="okDisable"
    >
      <ValidationObserver v-slot="{ handleSubmit }" ref="form">
        <form class="form" autocomplete="off">
          <div class="container">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Gán ticket cho khách hàng</label>
                  <ValidationProvider vid="phone" name="Số điện thoại"
                                      :rules="{  }"
                                      v-slot="{ errors,classes }">
                    <el-select
                        v-model="ticket.user_id"
                        filterable
                        clearable
                        remote
                        reserve-keyword
                        placeholder="Nhập số điện thoại"
                        :remote-method="remoteMethod"
                        :loading="search_loading">
                      <el-option
                          v-for="item in users"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id">
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Người phản hồi <span class="text-danger">*</span></label>
                  <ValidationProvider vid="nguoi_phan_hoi" name="Người phản hồi"
                                      :rules="{ required: true ,max : 50 }"
                                      v-slot="{ errors,classes }">
                    <el-input placeholder="Nhập họ tên người phản hồi"
                              v-model="ticket.nguoi_phan_hoi"></el-input>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>

                </div>
                <div class="form-group">
                  <label>Vấn đề phòng ban <span class="text-danger">*</span></label>
                  <ValidationProvider vid="van_de_phong_ban" name="Vấn đề phòng ban"
                                      :rules="{ required: true }"
                                      v-slot="{ errors,classes }">
                    <el-select filterable class="w-100" placeholder="Chọn phòng ban"
                               v-model="ticket.van_de_phong_ban"
                               clearable
                               @input="handleDepartment"
                    >
                      <el-option
                          v-for="item in departments"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"

                      >
                        <span style="float: left">{{ item.name }}</span>
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Thời gian khách hàng nhắn<span class="text-danger">*</span></label>
                  <ValidationProvider vid="send_message_at" name="Thời gian khách hàng nhắn"
                                      :rules="{ required: true }"
                                      v-slot="{ errors,classes }">
                    <section>
                      <date-picker
                          type="datetime"
                          placeholder="Chọn thời gian"
                          v-model="ticket.send_message_at"
                          format="HH:mm:ss DD-MM-YYYY"
                          valueType="YYYY-MM-DD HH:mm:ss"
                          :default-value="new Date()"
                          :class="classes"
                      ></date-picker>
                    </section>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <el-checkbox label="Chuyển phòng ban xử lý" @change="handleCheckbox"
                               v-model="ticket.checkbox_department_handle"></el-checkbox>
                </div>
                <div style="font-size: 15px; color: rgba(69, 70, 116, 1)" class="d-flex">
                  <span for="" class="mr-3">Độ ưu tiên</span>
                  <el-radio-group class="pt-1" v-model="ticket.c">
                  <el-radio style=" color: rgba(69, 70, 116, 1)" :label="1">Tiêu chuẩn</el-radio>
                  <el-radio  style=" color: rgba(69, 70, 116, 1)" :label="2">Ưu tiên</el-radio>
                </el-radio-group>
              </div>
            </div>
            <hr>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Nguồn<span class="text-danger">*</span></label>
                  <ValidationProvider vid="source" name="Nguồn"
                                      :rules="{ required: true }"
                                      v-slot="{ errors,classes }">
                    <el-select filterable class="w-100" placeholder="Danh mục"
                               v-model="ticket.source"
                               clearable>
                      <el-option
                          v-for="item in sources"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      >
                        <span style="float: left">{{ item.name }}</span>
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>

                <div class="form-group">
                  <label>Đối tượng phản hồi <span class="text-danger">*</span></label>
                  <ValidationProvider vid="doi_tuong_phan_hoi" name="Đối tượng phản hồi"
                                      :rules="{ required: true }"
                                      v-slot="{ errors,classes }">
                    <el-select filterable class="w-100" placeholder="Đối tượng phản hồi"
                               v-model="ticket.doi_tuong_phan_hoi"
                               clearable
                    >
                      <el-option
                          v-for="item in objectFeedback"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      >
                        <span style="float: left">{{ item.name }}</span>
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Danh mục <span class="text-danger">*</span></label>
                  <ValidationProvider vid="category_ticket_id" name="Danh mục"
                                      :rules="{ required: true }"
                                      v-slot="{ errors,classes }">
                    <el-select filterable class="w-100" placeholder="Danh mục"
                               v-model="ticket.category_ticket_id"
                               clearable
                    >
                      <el-option
                          v-for="item in categories"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"

                      >
                        <span style="float: left">{{ item.name }}</span>
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="form-group">
                  <label>Thời gian trả lời<span class="text-danger">*</span></label>
                  <ValidationProvider vid="reply_message_at" name="Thời gian trả lời"
                                      :rules="{ required: true }"
                                      v-slot="{ errors,classes }">
                    <section>
                      <date-picker
                          type="datetime"
                          placeholder="Chọn thời gian"
                          v-model="ticket.reply_message_at"
                          format="HH:mm:ss DD-MM-YYYY"
                          valueType="YYYY-MM-DD HH:mm:ss"
                          :default-value="new Date()"
                          :class="classes"
                      ></date-picker>
                    </section>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div class="form-group" v-if="ticket.checkbox_department_handle">
                  <label>Phòng ban xử lý<span class="text-danger">*</span></label>
                  <ValidationProvider vid="phong_ban_xu_ly" name="Phòng ban xử lý"
                                      :rules="{required: true}"
                                      v-slot="{ errors,classes }">
                    <el-select filterable class="w-100" placeholder="Chọn phòng ban xử lý"
                               v-model="ticket.phong_ban_xu_ly"
                               clearable
                    >
                      <el-option
                          v-for="item in departments"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      >
                        <span style="float: left">{{ item.name }}</span>
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
              </div>
              <hr class="row col-xl-12">
            </div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <label>Nội dung</label>
                  <el-input
                      type="textarea"
                      placeholder="Nội dung"
                      v-model="ticket.content"
                      maxlength="3000"
                      show-word-limit
                  >
                  </el-input>
                </div>
              </div>
            </div>
            <p>Ảnh/Video:</p>
            <UploadVideo @emitUpdateFile="emitUpdateFile"></UploadVideo>
          </div>
        </form>
      </ValidationObserver>
    </b-modal>
  </div>
</template>

<script>
import UploadVideo from "../../../tickets/components/UploadVideo";
import {GET_USER_BY_PHONE} from "@/core/services/store/user/users.module";
import {
  CREATE_TICKET, GET_TICKET_CATEGORIES
} from "@/core/services/store/user/ticket.module";
// Date picker
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default {
  name: "ModalCreateTicket",
  components: {UploadVideo, DatePicker},
  props: {
    sources: {
      type: Array,
      default: () => {
        return [];
      }
    },
    departments: {
      type: Array,
      default: () => {
        return [];
      }
    },
    objectFeedback: {
      type: Array,
      default: () => {
        return [];
      }
    }
  },
  data() {
    return {
      okDisable: false,
      search_loading: false,
      ticket: this.changeTicket(),
      fileList: [],
      users: [],
      categories: [],
      priorityDefine: {
        1: 'Bình thường',
        2: 'Ưu tiên',
      },
      priority: ''

    }
  },
  methods: {
    handleChange(file) {
      let count = this.fileList.filter((value) => {
        return value.uid == file.uid
      });

      if (count.length != 0) {
        return false;
      }
      this.fileList.push(file);
      this.stt++;
    },
    changeTicket() {
      return {
        user_id: '',
        nguoi_phan_hoi: '',
        doi_tuong_phan_hoi: '',
        van_de_phong_ban: '',
        category_ticket_id: '',
        phong_ban_xu_ly: 1,
        content: '',
        source: '',
        status: 1,
        is_auto: 3,
        send_message_at: null,
        reply_message_at: null,
        priority: 1
      }
    },
    getTicketCategories() {
      this.$store.dispatch(GET_TICKET_CATEGORIES, {
        limit: 1000,
        status: 1,
        van_de_phong_ban: this.ticket.van_de_phong_ban
      }).then((data) => {
        this.categories = data.data;
      })
    },
    handleDepartment() {
      this.categories = [];
      this.ticket.category_ticket_id = '';
      this.ticket.phong_ban_xu_ly = this.ticket.van_de_phong_ban;
      this.getTicketCategories();
    },
    handleCheckbox() {
      if (this.ticket.checkbox_department_handle) {
        this.ticket.phong_ban_xu_ly = this.ticket.van_de_phong_ban;
      }
    },
    handleOk(bvModalEvent) {
      bvModalEvent.preventDefault();
      this.callApi();
    },
    
    callApi() {
      this.okDisable = true;
      this.setDepartmentHandle();
      let payload = this.getPayload();
      this.$store.dispatch(CREATE_TICKET, payload).then((data) => {
        this.okDisable = false;
        this.fileList = [];
        this.ticket = this.changeTicket();
        this.$bvToast.toast(data.message, {
          title: 'Thêm mới',
          variant: 'success',
          solid: true
        });
        this.$emit('create-success');
        this.$emit('create-done', data.data ?? '');
        this.closeModal();
      }).catch((e) => {
        this.okDisable = false;
        if (e.response.data.data.message_validate_form) {
          this.$refs.form.setErrors(e.response.data.data.message_validate_form);
        }
      });
    },

    getPayload() {
      this.setDepartmentHandle();
      let formData = new FormData();
      for (let key in this.ticket) {
        formData.append(key, this.ticket[key]);
      }
      for (let i = 0; i < this.fileList.length; i++) {
        formData.append('fileList[]', this.fileList[i].raw);
      }
      return formData;
    },
    // Set phòng ban xử lý
    setDepartmentHandle() {
      if (!this.ticket.checkbox_department_handle) {
        this.ticket.phong_ban_xu_ly = 1;
      }
    },
    emitUpdateFile(e) {
      this.fileList = e;
    },
    closeModal() {
      this.$bvModal.hide('modal-create-ticket');
    },
    remoteMethod(query) {
      this.search_loading = true;
      this.$store.dispatch(GET_USER_BY_PHONE, {
        phone: query
      }).then((data) => {
        this.search_loading = false;
        this.users = data.data;
      })
      if (query !== '') {
        this.search_loading = true;
        setTimeout(() => {
          this.search_loading = false;
          this.options = this.list.filter(item => {
            return item.label.toLowerCase()
                .indexOf(query.toLowerCase()) > -1;
          });
        }, 200);
      } else {
        this.options = [];
      }
    },
  }
}
</script>

<style scoped>
.el-select {
  width: 100%;
}

.el-date-editor.el-input, .el-date-editor.el-input__inner {
  width: 100%;
}
</style>