<template>
  <div class="container">

    <div class="row" id="ticket-show">
      <div class="col-md-6" >
        <div class="card card-custom gutter-b" >
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Thông tin ticket
              </h3>
            </div>
            <div v-if="ticket.category_chamsoc != 10 && ticket.category_chamsoc != 11 && ticket.category_chamsoc != 13">
              <div v-if="this.$route.query.show_action != 1">
                <button v-if="is_edit" class="btn btn-primary mt-5 mb-5" @click="is_edit = !is_edit">Sửa</button>
                <div class="row d-flex justify-content-end" v-if="!is_edit">
                  <button class="btn btn-secondary mr-2 mt-5 mb-5" @click="is_edit = true">Hủy bỏ</button>
                  <button class="btn btn-primary mt-5 mb-5"
                          @click="update">Cập nhật
                  </button>
                </div>
              </div>
          </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Mã ticket:</label>
                  <input type="text" class="form-control form-control-solid" :value="ticket.id"
                         placeholder="Mã ticket" disabled="">
                </div>
                <div class="form-group" v-if="ticket.category_chamsoc != 13 && ticket.category_chamsoc != 14 && ticket.category_chamsoc != 16">
                  <label>Phân loại: </label>
                  <input type="text" class="form-control form-control-solid"
                  placeholder="Phân loại" value="Chăm sóc học viên" disabled="">
                </div>
                <!--Tùy biến-->

                <div v-if="ticket.category_chamsoc == 1 || ticket.category_chamsoc == 7 && ticket.category_chamsoc != 16">
                  <div class="form-group">
                    <label>Các buổi đã nghỉ:</label>
                    <input type="text" class="form-control form-control-solid"
                           placeholder="Các buổi đã nghỉ" :value="ticket.lesson_leave" disabled="">
                  </div>
                </div>
                <div v-if="ticket.category_chamsoc == 14">
                  <div class="form-group">
                    <label>Tổng số buổi nghỉ</label>
                    <input type="text" class="form-control form-control-solid"
                           placeholder="Các buổi đã nghỉ" :value="ticket.count_leave_ticket" disabled>
                  </div>
                </div>
                <div>
                  <div v-if="ticket.category_chamsoc == 10 || ticket.category_chamsoc == 11" class="form-group">
                    <label>Bên xử lý:</label>
                    <input type="text" class="form-control form-control-solid"
                        :value="phong_ban_xu_ly_define[ticket.phong_ban_xu_ly]" disabled="">
                  </div>
                </div>
<!--                <div>-->
<!--                  <div v-if="ticket.category_chamsoc == 14" class="form-group">-->
<!--                    <label>Lý do nghỉ:</label>-->
<!--                    <el-select v-model="ticket.reason_id"-->
<!--                               placeholder=""-->
<!--                               :disabled="is_edit"-->
<!--                               clearable-->
<!--                               collapse-tags-->
<!--                               class="w-100"-->
<!--                    >-->
<!--                      <el-option-->
<!--                        v-for="(item, index) in listReasonOffLesson"-->
<!--                        :key="index"-->
<!--                        :label="item.name"-->
<!--                        :value="item.id">-->
<!--                        <span style="float: left">{{ item.name }}</span>-->
<!--                      </el-option>-->
<!--                    </el-select>-->
<!--                  </div>-->
<!--                </div>-->
                <div>
                  <div class="form-group" v-if="ticket.category_chamsoc != 15 && ticket.category_chamsoc != 13 && ticket.category_chamsoc != 14 && ticket.category_chamsoc != 16">
                    <label>Bên gửi phản hồi:</label>
                    <input type="text" class="form-control form-control-solid"
                           placeholder="Bên gửi phản hồi" :value="transer == 1 ? 'TTHT' : (transer == 2 ? 'QLHV' : (ticket.is_auto == 1 || ticket.is_auto == 3 ? 'TTHT' : ticket.is_auto == 2 ? 'QLHV' : ''))" disabled="">
                  </div>
                </div>
                <div class="form-group" v-if="ticket.category_chamsoc == 1 || ticket.category_chamsoc == 7">
                  <label>Lý do {{show_reason[ticket.category_chamsoc]}} :</label>
                  <el-select v-model="ticket.reason_id"
                             placeholder=""
                             :disabled="is_edit"
                             clearable
                             collapse-tags
                             class="w-100"
                  >
                     <el-option
                        v-for="item in listReason"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                  </el-select>
                </div>
                <div class="form-group" v-if="ticket.category_chamsoc == 9">
                  <label>Họ tên người sử dụng App :</label>
                  <input type="text" class="form-control form-control-solid"
                         placeholder="Các buổi đã nghỉ" :value="ticket?.customer?.name_use_app" disabled>
                </div>
                <div class="form-group" >
                  <label>Chi nhánh :</label>
                  <input type="text" class="form-control form-control-solid"
                         placeholder="Các buổi đã nghỉ" :value="ticket?.branch?.name" disabled>
                </div>
                <div v-if="ticket.category_chamsoc === 10">
                  <div class="form-group">
                    <label>Buổi kiếm tra:</label>
                    <input type="text" class="form-control form-control-solid" disabled=""
                           :value="examTypeDefine[ticket.exam_type]" placeholder="Buổi kiểm tra">
                  </div>
                  <div class="form-group">
                    <label>Kết quả:</label>
                    <input type="text" class="form-control form-control-solid" disabled=""
                           :value="ticket.exam_total_score" placeholder="Kết quả">
                  </div>
                </div>
                <div v-if="ticket.category_chamsoc == 6">
                  <div class="form-group">
                    <label>Số sao:</label>
                    <input type="text" class="form-control form-control-solid" disabled=""
                           :value="ticket.feedback_star" placeholder="Số sao">
                  </div>
                </div>
                <div class="form-group">
                  <label >Thời gian tạo ticket:</label>
                  <input type="text" class="form-control form-control-solid"
                         :value="ticket.created_at | formatDateTimeAsia"
                         placeholder="Thời gian tạo ticket" disabled="">
                </div>
                <div v-if="ticket.category_chamsoc == 14" class="form-group">
                  <label>Lý do nghỉ:</label>
                  <el-select v-model="ticket.reason_id"
                             placeholder="Lý do nghỉ"
                             :disabled="is_edit"
                             clearable
                             collapse-tags
                             class="w-100"
                  >
                    <el-option
                      v-for="(item, index) in listReasonOffLesson"
                      :key="index"
                      :label="item.name"
                      :value="item.id">
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                </div>
                <div class="form-group" v-if="ticket.category_chamsoc == 16">
                  <label>Phân loại :</label>
                  <el-select
                    v-model="ticket.type_auto"
                    placeholder=""
                    :disabled="is_edit"
                    clearable
                    collapse-tags
                    class="w-100"
                    @change="changeType()"
                  >
                    <el-option :value="1" label="Có khiếu nại">Có khiếu nại</el-option>
                    <el-option :value="2" label="Không có khiếu nại">Không có khiếu nại</el-option>
                  </el-select>
                </div>
                <div class="form-group" v-if="show">
                  <div class="form-group mb-1">
                    <label for="exampleTextarea" >Chi tiết vấn đề
                      <span class="text-danger">*</span></label>
                    <textarea class="form-control" id="exampleTextarea" rows="3"
                              v-model="ticket.reason_absence" :disabled="is_edit"></textarea>
                  </div>
                </div>
                <!--End-->
              </div>
              <div class="col-md-6">

                <div class="form-group">
                  <label>Danh mục:</label>
                  <input type="text" class="form-control form-control-solid"
                         v-model="category_chamsoc_define[ticket.category_chamsoc]"
                         placeholder="Danh mục" disabled="">
                </div>
                <div class="form-group" v-if="ticket.category_chamsoc == 9">
                  <label>Số điện thoại người sử dụng App :</label>
                  <input type="text" class="form-control form-control-solid"
                         v-model="ticket?.customer.phone_use_app"
                         placeholder="Số điện thoại" disabled="">
                </div>
                <div class="form-group">
                  <label>Lớp học :</label>
                  <input type="text" class="form-control form-control-solid"
                         :value="ticket?.classes?.name"
                         placeholder="Lớp học" disabled>
                </div>

                <div v-if="ticket.category_chamsoc == 10 || ticket.category_chamsoc == 11" class="form-group">
                  <label>Buổi học:</label>
                  <input type="text" class="form-control form-control-solid"
                         :value="ticket.lesson_leave"
                         placeholder="Buổi học" disabled="">
                </div>
                <div v-else class="form-group">
                  <label>Bên xử lý<span class="text-danger">*</span></label>
                  <ValidationProvider vid="phone" name="Phòng ban xử lý"
                                      :rules="{ required: true }"
                                      v-slot="{ errors,classes }">
                    <el-select filterable class="w-100" placeholder="Phòng ban xử lý"
                               v-model="ticket.phong_ban_xu_ly "
                               clearable
                               :disabled="is_edit"
                    >
                      <el-option
                          v-for="item in departments"
                          :key="item.id"
                          :label="item.name"
                          :value="item.id"
                      >
                        <span style="float: left">{{ item.name }}</span>
                      </el-option>
                    </el-select>
                    <div class="fv-plugins-message-container">
                      <div data-field="name" data-validator="notEmpty"
                           class="fv-help-block">{{
                          errors[0]
                        }}
                      </div>
                    </div>
                  </ValidationProvider>
                </div>
                <div v-if="ticket.category_chamsoc == 14" class="form-group">
                    <label>Thời gian nghỉ:</label>
                    <input type="text" class="form-control" :value="ticket.time_off | formatDateTimeAsia" disabled>
                </div>

                <div v-if="ticket.category_chamsoc == 13" class="form-group">
                  <label>Lý do:</label>
                  <el-select v-model="ticket.reason_id"
                             placeholder=""
                             :disabled="is_edit"
                             clearable
                             collapse-tags
                             class="w-100"
                  >
                    <el-option
                      v-for="(item, index) in listReasonOffOpenDate"
                      :key="index"
                      :label="item.name"
                      :value="item.id">
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                </div>
                <div class="form-group" v-if="ticket.category_chamsoc != 16 && ticket.category_chamsoc != 14 && ticket.category_chamsoc != 13 && ticket.category_chamsoc != 9 && ticket.category_chamsoc != 6 && ticket.category_chamsoc != 10 && ticket.category_chamsoc != 11">
                  <label >Phân loại nghỉ:</label>
                  <input  type="text" class="form-control form-control-solid"
                         :value="changeTypeOff(ticket.category_chamsoc, ticket.val)"
                         placeholder="Phân loại nghỉ" disabled="">
                </div>

                <div class="form-group" v-if="show">
                  <label>Vấn đề :</label>
                  <el-select v-model="ticket.reason_id"
                             placeholder=""
                             :disabled="is_edit"
                             clearable
                             collapse-tags
                             class="w-100"
                             placeholder="Phân loại vấn đề"
                  >
                    <el-option
                      v-for="(item, index) in problems"
                      :key="index"
                      :label="item.name"
                      :value="item.id">
                      <span style="float: left">{{ item.name }}</span>
                    </el-option>
                  </el-select>
                </div>
                <div v-if="ticket.category_chamsoc == 6">
                  <div class="form-group">
                    <label>Lý do feedback:</label>
                    <!-- <input type="text" class="form-control form-control-solid" :disabled="is_edit" -->
                           <!-- :value="ticket.feedback_ticket_comment" placeholder="Lý do feedback"> -->
                            <el-select  v-model="ticket.feedback_ticket_comment"
                              size="sm"
                              placeholder=""
                              :disabled="is_edit"
                              clearable
                              collapse-tags
                              class="overflow-auto w-100"
                            >
                              <el-option
                                  v-for="item in listReason"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id.toString()">
                              <span style="float: left">{{ item.name}}</span>
                              </el-option>
                            </el-select>
                  </div>
                </div>
                <div v-if="ticket.category_chamsoc == 10 || ticket.category_chamsoc == 11">
                  <div class="form-group">
                    <label>Tình trạng BTVN</label>
                    <input type="text" class="form-control form-control-solid" disabled=""
                           :value="ticket.category_chamsoc == 10 ? 'Kết quả thấp' : 'Không làm BTVN'" placeholder="Lý do feedback">
                  </div>
                </div>
              </div>
              <div v-if="ticket.category_chamsoc == 10 || ticket.category_chamsoc == 11">
                    <button :disabled="viewHomework" v-if="ticket.category_chamsoc == 10" class="btn btn-sm btn-outline-info m-4" @click="viewHomeworkBadScore">Xem chi tiết BTVN</button>
                    <button :disabled="viewHomework" v-if="ticket.category_chamsoc == 11" class="btn btn-sm btn-outline-info m-4" @click="viewHomeworkNotWorks">Xem chi tiết BTVN</button>
              </div>
              <div class="col-md-12" v-if="ticket.category_chamsoc != 16 && ticket.category_chamsoc != 14 && ticket.category_chamsoc != 6 && ticket.category_chamsoc != 10 && ticket.category_chamsoc != 11 && ticket.category_chamsoc != 13">
                <div class="form-group mb-1">
                  <label for="exampleTextarea" >Chi tiết lí do
                    <span class="text-danger">*</span></label>
                  <textarea class="form-control" id="exampleTextarea" rows="3"
                            v-model="ticket.reason_absence" :disabled="is_edit"></textarea>
                </div>
              </div>
              <div class="col-md-12" v-if="ticket.category_chamsoc == 13">
                <button class="btn btn-sm btn-outline-info" @click="opentDetail">Xem chi tiết</button>
<!--                <router-link-->
<!--                  v-if="ticket && ticket.student_edutalk && ticket.student_edutalk.best_customer"-->
<!--                  :to="{ name: 'customer-crm-detail',-->
<!--                            params: {id: ticket.student_edutalk.id},-->
<!--                            query: { best_customer_id: ticket.student_edutalk.best_customer.id, active_name: 'cskh' }-->
<!--                            }" class="btn btn-sm btn-outline-info">Xem chi tiết-->
<!--                </router-link>-->
              </div>
              <div class="col-md-12" v-if="ticket.category_chamsoc == 6">
                <div class="form-group mb-1">
                  <label for="exampleTextarea">Chi tiết feedback
                    <span class="text-danger">*</span></label>
                  <textarea class="form-control" id="exampleTextarea" rows="3"
                            v-model="ticket.feedback_comment"></textarea>
                </div>
              </div>
            </div>
          </div>
          <div class="card-header">
            <div class="card-title">
              <h3 class="card-label">
                Thông tin học viên
              </h3>
            </div>
          </div>
          <div class="card-body">
            <div class="row">
              <div class="col-md-6">
                <div class="form-group">
                  <label>Họ tên:</label>
                  <input type="text" class="form-control form-control-solid" disabled=""
                         placeholder="Họ tên"
                         :value="ticket.student_edutalk ? ticket.student_edutalk.name : ''">
                </div>

              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <label>Số điện thoại liên hệ:</label>
                  <input type="text" class="form-control form-control-solid" disabled=""
                         placeholder="Số điện thoại"
                         :value="getPhone">
                  <el-button class="border border-dark p-3 rounded position-absolute" style="right: 15px; top: 25px;" @click="changePhone">
                    <i class="el-icon-phone-outline"></i>
                  </el-button>
                </div>

              </div>

            </div>
            <router-link
                v-if="ticket && ticket.student_edutalk && ticket.student_edutalk.best_customer"
                :to="{ name: 'customer-crm-detail',
                            params: {id: ticket.student_edutalk.id},
                            query: { best_customer_id: ticket.student_edutalk.best_customer.id, active_name: 'cskh' }
                            }" class="btn btn-sm btn-outline-info">Xem chi tiết
            </router-link>
          </div>
        </div>
      </div>
      <div class="col-md-6">
        <div class="card card-custom gutter-b">
          <div class="card-header">
            <div class="card-title  mb-4">
              <h3 class="card-label">
                Tình trạng xử lý
              </h3>
            </div>
            <div class="d-flex justify-content-between mt-5"
                 v-if="currentUser.user.permission.includes(permission.CHAM_SOC_HOC_VIEN)"
            >
              <FeedbackTicketModal  v-if="ticket.status == 3"
                                   :ticket="ticket"
                                   class="mr-3"
                                   @reloadFeedBack="reloadFeedBack">
              </FeedbackTicketModal>
              <RefuseSolution 
              v-if="currentUser.user.permission.includes(permission.NHAP_GIAI_PHAP_PHONG_BAN_XU_LY) && ticket.status == 2"
              :ticket="ticket"
              @refuseSolution="getTicketById"
              >
              </RefuseSolution>
              <MoveDepartmentTakeCareStudentModal
                  v-if="show_move_department"
                  :departments="departments"
                  class="mr-3"
                  :ticket="ticket"
                  @moveDepartment="getTicketById"
                  @dialogClassifyFeedback="modalClassifyFeedback"
              ></MoveDepartmentTakeCareStudentModal>
              <CancelMoveDepartment
                  v-else
                  class="mr-3"
                  :ticket="ticket"
                  :checkCancel="false"
                  @cancelMoveDepartment="getTicketById"
              ></CancelMoveDepartment>
              <ModalStatusSuccess
                  v-if="ticket.status == 2"
                  :ticket="ticket"
                  :show_action="this.$route.query.show_action"
                  @changeStatusSuccess="getTicketById"
              ></ModalStatusSuccess>
            </div>
          </div>
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <div class="card-title">
                <h4 class="card-label">Trạng thái chăm sóc</h4>
              </div>
              <div class="form-group">
                <label>Trạng thái</label>
                <p>
                      <span class="" v-if="ticket"
                      :class="statusDefineCss[ticket.status]">{{ statusDefine[ticket.status] }}</span>
                </p>
              </div>
            </div>
            <div class="row col-12" v-if="!boxCommentReason">
                  <button @click="boxCommentReason = true" style="width: 100%; height: 51px; text-align: start; color: rgba(0, 0, 0, 0.5);" class="border rounded border-1 pl-4">Nhập giải pháp hoặc bình luận</button>
            </div>

            <div class="form-group mb-1 col-md-12" v-if="boxCommentReason">
                <ValidationProvider vid="content" name="Nội dung" rules="required"
                                    v-slot="{ errors, classes }">
                  <div>
                    <i title="Đóng" @click="boxCommentReason = false" style="position: absolute; right: 18px; top: 5px" class="el-icon-close d-flex justify-content-end closes-ticket-show-log"></i>
                  </div>
                  <ckeditor style="position: relative;" :editor="editor" v-model="query.reason" ></ckeditor>
                  <div class="fv-plugins-message-container">
                    <div data-field="name" data-validator="notEmpty" class="fv-help-block">{{
                        errors[0]
                      }}
                    </div>
                  </div>
                </ValidationProvider>
                <div class="box-bottom-comment-reason d-flex justify-content-end">
                  <div class="d-flex align-items-end">
                    <el-checkbox v-if="ticket.status == 1" v-model="query.is_solution">Đánh dấu là giải pháp</el-checkbox>
                  </div>
                  <div class="">
                    <b-button v-if="!ticket.feedback_ticket_comment && ticket.category_chamsoc == 6" style="" variant="success" size="md" class="float-right ml-2" :disabled="loadingSave" :class="{'spinner spinner-white spinner-right' : loadingSave}" @click="dialogClassifyFeedback = true">Lưu</b-button>
                    <b-button v-else variant="success" size="md" class="float-right ml-2" :disabled="loadingSave" :class="{'spinner spinner-white spinner-right' : loadingSave}" @click="handlerCommentReasonTicket">Lưu</b-button>
                  </div>
                </div>
            </div>
              <div class="col-md-12 mt-8 show-comment" style="overflow: scroll; height: 94vh;">
                <div class="" style="scroll-behavior: smooth;">
                  <template class="w-100">
                  <el-tabs v-model="activeName" @tab-click="handleClick">
                    <el-tab-pane label="Tất cả" name="all">
                    </el-tab-pane>
                    <el-tab-pane label="Giải pháp" name="solution">
                    </el-tab-pane>
                    <el-tab-pane label="Bình luận" name="comment">
                    </el-tab-pane>
                    <AllTicketLog
                    v-loading="loadingTabName"
                    :tickets="ticketLog"
                    :tabName="activeName">
                    </AllTicketLog>
                  </el-tabs>
                </template>
              </div>
            </div>
            <!-- dialog phân loại feedback -->
            <el-dialog title="Phân loại feedback thấp" :visible.sync="dialogClassifyFeedback">
                <h4>Bạn vui lòng phân loại feedback thấp</h4>
                <div>
                  <div class="form-group mt-4">
                    <label>Lý do feedback <span class="text-danger">*</span></label>
                            <el-select v-model="ticket.feedback_ticket_comment"
                              placeholder="Chọn loại feedback"
                              clearable
                              collapse-tags
                              class="w-100"
                            >
                              <el-option
                                  v-for="item in listReason"
                                  :key="item.id"
                                  :label="item.name"
                                  :value="item.id.toString()">
                              <span style="float: left">{{ item.name}}</span>
                              </el-option>
                            </el-select>
                  </div>
                </div>
                <div slot="footer" class="dialog-footer">
                  <el-button class="rounded" style="background-color: #a09fa1; color: black;" @click="dialogClassifyFeedback = false">Huỷ bỏ</el-button>
                  <el-button class="rounded" :disabled="loadingClassifyFeedback" v-loading="loadingClassifyFeedback" style="background-color: #5f04b4; color: #ffff;" @click="handlerClassifyFeedbackTicket">Xác nhận</el-button>
                </div>
            </el-dialog>
          </div>
          </div>
        </div>
      </div>
      <el-dialog title="Tình hình học tập" :visible.sync="showModal">
        <chi-tiet-hoc-tap
          :loading="loading"
          :student-register-course="studentRegisterCourse"
          :classroom="classroomShow"
          :schedules="schedules"
        ></chi-tiet-hoc-tap>
      </el-dialog>
    </div>
</template>
<script>
import {SET_BREADCRUMB} from "../../../core/services/store/breadcrumbs.module";
import {
  GET_TICKET_BY_ID,
  GET_TICKET_REASON_BY_TYPE,
  UPDATE_TICKET_REASON,
  GET_LEARNING_SCHEDULE_ID,
  CLASSIFY_FEEDBACK_TICKET_LOW,
  REASON_ERROR, GET_SOURCE, GET_OBJECT_FEEDBACK, CATEGORY, CONNECT_STRINGEE, GET_DATA_STUDY_IN_CLASS
} from "../../../core/services/store/user/ticket.module";
import { CONTENT_HOMEWORK } from "../../../core/services/store/course/classes.module";
import HandleTicketModal from "./components/HandleTicketModal";
import RefuseSolution from "./components/RefuseSolution";
import UploadVideo from "./components/UploadVideo";
import FeedbackTicketModal from "./components/FeedbackTicketModal";
import {mapGetters} from "vuex";
import ModalStatusSuccess from "../crm/tickets/components/ModalStatusSuccess";
import CancelMoveDepartment from "@/view/pages/crm/tickets/components/CancelMoveDepartment";
import MoveDepartmentTakeCareStudentModal from "@/view/pages/crm/tickets/components/MoveDepartmentTakeCareStudentModal";
import ticket from "@/view/pages/tickets/Ticket";
import CustomEditor from "@/core/plugins/ckeditor5/custom-editor";
import {
  GET_DEPARTMENT_DEPARTMENT_TAKE_CARE_STUDENT
} from "@/core/services/store/user/department.module";
import {UPDATE_TICKET_FEEDBACK, SHOW_TICKET_LOG} from "@/core/services/store/ticket/ticket.module";
import AllTicketLog from "../crm/tickets/components/AllTicketLog.vue";
import ModalCreateTicket from "../crm/tickets/components/ModalCreateTicket";
import {CUSTOMER_SCHEDULE_LIST} from "../../../core/services/store/customer/customer-crm.module";
import ChiTietHocTap from "../customers/customerDetail/ChiTietHocTap";
import { StudyDetail } from "../customers/Model/studyDetail";

export default {
  name: "TicketShow",
  components: {
    ModalStatusSuccess,
    FeedbackTicketModal,
    UploadVideo,
    HandleTicketModal,
    CancelMoveDepartment,
    MoveDepartmentTakeCareStudentModal,
    AllTicketLog,
    RefuseSolution,
    ModalCreateTicket,
    ChiTietHocTap
  },
  data() {
    return {
      showModal: false,
      studentRegisterCourse: [],
      classroomShow: [],
      schedules: [],
      loading: false,
      category: CATEGORY,
      checkChangeIcon: false,
      ticket: {
        title: '',
        category_chamsoc: '',
      },
      editor: CustomEditor,
      is_edit: true,
      reasonCheck: '',
      viewHomework: false,
      learning_schedule_id : '',
      ticket_id: '',
      activeName: 'all',
      boxCommentReason: false,
      loadingSave: false,
      loadingReffuseSolution: false,
      loadingTabName: false,
      dialogClassifyFeedback: false,
      loadingClassifyFeedback: false,
      transferDepartmentClassifyFeedback: false,
      listReason: [],
      ticketLog: [],
      query: {
              ticket_id: '',
              type: 'change_status',
              user_handle: '',
              old_content: '',
              new_content: '',
              content: '',
              reason: '',
              reason_refuse: '', 
              is_auto: 1,
              is_solution: false
            },
      phong_ban_xu_ly_define: {
        1: 'TTHT',
        2: 'Tài chính',
        3: 'Nhân sự',
        4: 'SPNN',
        5: 'CNTT',
        6: 'Đối tác',
        7: 'Đào tạo',
        8: 'Brand',
        10: 'Digital',
        11: 'Marketing',
        12: 'R&D',
        13: 'Pháp lý',
        14: 'QLHV',
        15: 'CSVC',
      },

      category_chamsoc_define: {
        1: 'Nghỉ học 2 buổi',
        2: 'Điểm kiểm tra thấp',
        3: 'Không làm bài',
        4: 'Chua đóng tiền học',
        5: 'Đã đóng tiền học',
        6: 'Feedback thấp',
        7: 'Nghỉ học 1 buổi',
        8: 'Vắng có phép',
        9: 'Hướng dẫn app cho phụ huynh',
        10: 'Kết quả BTVN không tốt',
        13: 'Nhắc nhở bảo hành',
        14: 'Nghỉ 1 buổi không phép',
        15: 'Nghỉ buổi khai giảng',
        16: 'Chăm sóc học viên giữa khóa'
      },
      statusDefine: {
        0: 'Hủy',
        1: 'Chờ xử lý',
        2: 'Đã nhập giải pháp',
        3: 'Đã hoàn thành'
      },
      statusDefineCss: {
        0: 'badge badge-danger',
        1: 'badge badge-warning',
        2: 'badge badge-primary',
        3: 'badge badge-success'
      },
      examTypeDefine: {
        'mid': 'Giữa khóa',
        'last': 'Cuối khóa',
      },
      show_reason: {
        1: 'nghỉ',
        6: 'feedback',
        7: 'nghỉ'
      },
      departments: [],
      show_move_department: false,
      transer: '',
      config: {
        showMode: 'none',
        top: 45,
        right: 250,
        arrowLeft: 155,
        arrowDisplay: 'top',
        fromNumbers: [
          { alias: 'Number-1', number: '+84899199586' },
          { alias: 'Number-2', number: '+2222' }
        ],
        askCallTypeWhenMakeCall: false,
        appendToElement: null,
        makeAndReceiveCallInNewPopupWindow: false
      },
      accessToken: '',
      numbers: '',
      fromNumber: '',
      toNumber: '',
      callType: '',
      messageError: '',
      reasonError: REASON_ERROR,
      callStringeeId: '',
      checkIncoming: false,
      dialogAssignTicket: false,
      sources: [],
      formTicket: {
        ticket_id: '',
      },
      search_loading: false,
      dataFindTicket: {},
      objectFeedback: [],
      loadingAssignee: false,
      problems: [
        {
          id: 1,
          name:  'Feedback Giáo viên',
        },
        {
          id: 19,
          name:  'Feedback trợ giảng',
        },
        {
          id: 3,
          name:  'Cơ sở vật chất',
        },
        {
          id: 20,
          name:  'Xin thêm tài liệu',
        },        {
          id: 21,
          name:  'Feedback App BTVN',
        },
        {
          id: 22,
          name:  'Yêu cầu bổ trợ',
        },
        {
          id: 23,
          name:  'Khác',
        },
      ],
      listReasonOffLesson: [
        {
          id: 9,
          name:  'Bận việc cá nhân',
        },
        {
          id: 10,
          name:  'Trùng lịch học',
        },
        {
          id: 11,
          name:  'Nghỉ ốm',
        },
        {
          id: 12,
          name:  'Du lịch',
        },        {
          id: 13,
          name:  'Quân sự/ Thực tập',
        },
        {
          id: 14,
          name:  'Bảo lưu',
        },
        {
          id: 24,
          name:  'Nghỉ hẳn',
        },
        {
          id: 23,
          name:  'Khác',
        },
      ],
      listReasonOffOpenDate: [
        {
          id: 25,
          name:  '2 buổi không làm BTVN',
        },
        {
          id: 26,
          name:  'Nghỉ học 2 buổi',
        },
        {
          id: 27,
          name:  'Nghỉ 1 buổi + Không làm BTVN 1 buổi.',
        },
      ],
      type: '',
      show: false
    }
  },

  computed: {
    ...mapGetters(["currentUser"]),
    getPhone(){
      if (!this.ticket.student_edutalk) {
        return  '';
      }

      if (this.ticket.student_edutalk.phone_other){
        return this.ticket.student_edutalk.phone_other;
      }

      if (this.ticket.student_edutalk.phone){
        return this.ticket.student_edutalk.phone;
      }

      return  '';
    }
  },
  created() {
    // if (this.currentUser.userTokenStringee) {
    //   this.config.showMode = 'full';
    // }
    this.$store.dispatch(SET_BREADCRUMB, [
      {title: "Chăm sóc học viên", route: 'ticket-index'},
      {title: "Chi tiết ticket"}
    ]);
  },
  async mounted() {
    await this.getTicketById(true);
    await this.getReason();
    this.getDepartments();
    this.getSource();
    this.getObjectFeedback();
    if ($("div").hasClass('hide')) {
      document.getElementById("buttonCall").classList.add('show');
      document.getElementById("buttonCall").classList.remove('hide');
    }
  },
  methods: {
     getScheduleId() {
      this.ticket_id = this.ticket.id;
          this.$store.dispatch(GET_LEARNING_SCHEDULE_ID, {
            ticket_id: this.ticket_id
          }).then((data) => {
            this.learning_schedule_id = data.data;
            if(data.error) {
              this.viewHomework = true;
            }
          })
    },

    async getTicketById(e) {
      await this.$store.dispatch(GET_TICKET_BY_ID, this.$route.params.id).then((data) => {
        if (!data.error) {
          this.ticket = data.data;
          this.transer = data.data.transer_ticket_log ? data.data.transer_ticket_log[0]?.assignor_id : '';
          let category_chamsoc = this.ticket.category_chamsoc;
          if(category_chamsoc == 10 || category_chamsoc == 11) {
            this.getScheduleId();
          }

          if (!this.ticket.phong_ban_xu_ly) {
            this.ticket.phong_ban_xu_ly = 1;
          }
          if (this.ticket.reason_id === 0) {
            this.ticket.reason_id = '';
          }
          if (this.ticket.type_auto == 1)
          {
            this.show = true;
          }
          this.checkShowMoveDepartment(); 
          if(e) {
            this.showTicketLog();
          }
         
        }
      })
    },

    update(e) {
      e.preventDefault();
      let params = {
        id: this.$route.params.id,
        reason: this.ticket.reason_id,
        feedback_comment: this.ticket.feedback_comment,
        feedback_ticket_comment: this.ticket.feedback_ticket_comment,
        phong_ban_xu_ly: this.ticket.phong_ban_xu_ly,
        reason_absence: this.ticket.reason_absence,
        type_auto: this.ticket.type_auto,
      };
      this.$store.dispatch(UPDATE_TICKET_REASON, params).then((data) => {
        this.is_edit = true;
        this.$notify.success({
            title: 'Thành công',
            message: 'Cập nhập ticket thành công',
            offset: 100
          });
      });
    },
    async getReason() {
      await this.$store.dispatch(GET_TICKET_REASON_BY_TYPE, this.ticket.category_chamsoc).then((res) => {
        if (!res.error) {
          this.listReason = res.data;
        }
      });
    },
    getSource() {
      this.sources = [];
      this.$store.dispatch(GET_SOURCE).then((data) => {
        for (const key in data.data) {
          this.sources.push({
            id: key,
            name: data.data[key]
          })
        }
      })
    },
    getDepartments() {
      this.$store.dispatch(GET_DEPARTMENT_DEPARTMENT_TAKE_CARE_STUDENT, {
      }).then((data) => {
        this.departments = data.data;
      })
    },
    checkShowMoveDepartment() {
      if (!this.ticket.phong_ban_xu_ly || (this.ticket.category_chamsoc == 6 && this.ticket.phong_ban_xu_ly == 1) || this.ticket.phong_ban_xu_ly == 1) {
        this.show_move_department = true;
      } else {
        this.show_move_department = false;
      }
    },
    viewHomeworkBadScore(){
          const routeData = this.$router.resolve({ name: "content-homework",params: {id : this.ticket.classroom_id},
          query: { classroom_id: this.ticket.classroom_id,learning_schedule_id:this.learning_schedule_id,student_id :
                this.ticket.user_id, lesson: this.ticket.lesson_leave , type:'view-result'}});
          window.open(routeData.href, '_blank');
    },

    viewHomeworkNotWorks(){
            const routeData = this.$router.resolve({ name: "content-homework",params: {id : this.ticket.lesson_leave},
                 query: { classroom_id: this.ticket.classroom_id, learning_schedule_id:this.learning_schedule_id}});
                 window.open(routeData.href, '_blank');
    },

    modalClassifyFeedback() {
      this.dialogClassifyFeedback = true;
      this.transferDepartmentClassifyFeedback = true;
    },

    showTicketLog() {
      this.loadingTabName = true;
      this.$store.dispatch(SHOW_TICKET_LOG, {
          id: this.$route.params.id,
          type: this.activeName
        }).then((data) => {
          this.loadingTabName = false;
          this.ticketLog = data.data
        })
    },
   handlerCommentReasonTicket() {
    let reason = this.$el.querySelector(".ck-content").innerHTML;
    this.loadingSave = true;
    var is_solution =  this.query.is_solution ? 1 : 0;
    setTimeout(() => {
      this.$store.dispatch(UPDATE_TICKET_FEEDBACK,{
            ticket_id: this.$route.params.id,
            is_solution : is_solution,
            reason: reason,
            status: this.ticket.status,
            status_feedback: 2
            }).then((res) => {
              this.loadingSave = false;
              this.boxCommentReason = false;
              this.query.reason = '';
              this.activeName = "all";
              this.getTicketById();
              this.showTicketLog();
                if(is_solution) {
                  this.query.is_solution = false;
                  this.$notify.success({
                  title: 'Thành công',
                  message: 'Nhập giải pháp thành công',
                  offset: 100
                });
                }else {
                  this.$notify.success({
                  title: 'Thành công',
                  message: 'Nhập bình luận thành công',
                  offset: 100
                });
                }
                this.dialogClassifyFeedback = false;
            }).catch((e) => {
              
            }).finally(() => {  
      });
    }, 3000);
    clearTimeout();
    },

   reloadFeedBack(e) {
      this.getTicketById(e);
      this.activeName = "all";
    },

    handleClick() {
      this.showTicketLog();
    },

    handlerClassifyFeedbackTicket() {
      if(!this.ticket.feedback_ticket_comment) {
              this.$notify.warning({
                title: 'Thiếu dữ liệu',
                message: 'Vui lòng chọn lý do feedback',
                offset: 100
              });
              return false;
      }
      this.loadingClassifyFeedback = true;
      this.$store.dispatch(CLASSIFY_FEEDBACK_TICKET_LOW,{
        id: this.$route.params.id,
        feedback_ticket_comment: this.ticket.feedback_ticket_comment
        }).then((res) => {
          this.getTicketById();
              this.$notify.success({
                title: 'Thành công',
                message: 'Phân loại feedback thấp thành công',
                offset: 100
              });
          this.loadingClassifyFeedback = false;
          this.dialogClassifyFeedback = false;
          this.activeName = "all";
          this.showTicketLog();
        }).catch((e) => {
          
        }).finally(() => {
      });
    },

    getObjectFeedback() {
      this.objectFeedback = [];
      this.$store.dispatch(GET_OBJECT_FEEDBACK).then((data) => {
        for (const key in data.data) {
          this.objectFeedback.push({
            id: key,
            name: data.data[key]
          })
        }
      })
    },
    changePhone() {
        StringeeSoftPhone.config({ showMode: 'full' });
        document.getElementById("buttonCall").classList.add('show');
        StringeeSoftPhone._iframe.contentWindow.stringeePhone.changeInput(this.getPhone)
    },
    opentDetail()
    {
      let classroom_id = this.ticket.classroom_id;
      this.classroomShow = this.ticket.classes;

      this.$store.dispatch(GET_DATA_STUDY_IN_CLASS, {
        user_id: this.ticket.user_id,
        class_id: classroom_id
      }).then((data) => {
        this.studentRegisterCourse = data.data;
        console.log(this.studentRegisterCourse, 'studentRegisterCourse')
        this.getSchedule();

      })

    },
    getSchedule() {
      try {
        this.$store.dispatch(CUSTOMER_SCHEDULE_LIST, this.studentRegisterCourse.id).then((res) => {
          console.log(res?.data, 'res?.data')
          if (res?.data === null) {
            this.schedules = []
            this.loading = false
            return
          }
          const result = res?.data?.result
          this.schedules = result.map(item => new StudyDetail(
            item?.lesson,
            item?.day,
            item?.is_scheduled,
            item?.rating,
            item?.homework_status,
            item?.teacher_comment,
            item?.comment_phase,
            item?.type,
            result?.length,
            item?.student_feedback,
            item?.comment,
            item?.attendance_reason
          ));
          this.loading = false
          this.showModal = true
        }).catch(e => {
          console.log(e)
        })
      } catch (error) {
        console.log(error)
      }
    },
    changeTypeOff(type, val)
    {
      let text = '';
      switch (type) {
        case '7':
          text = 'Không phép';
          break;
        case '16':
          text = 'Có khiếu nại';
          break;
        default:
          if (val == 2) {
            text = 'Có phép';
          } else if (val == 0) {
            text = 'Không phép';
          }
          break;
      }
      console.log(type, text)

      return text;
    },
    changeReason(id_reason_absence) {
      let text;

      switch (id_reason_absence) {
        case 1:
          text = 'Feedback Giáo viên';
          break;
        case 2:
          text = 'Feedback Giáo trình';
          break;

        case 3:
          text = 'Cơ sở vật chất';
          break;

        case 20:
          text = ' Xin thêm tài liệu';
          break;

        case 21:
          text = ' Feedback App BTVN';
          break;

        case 22:
          text = 'Yêu cầu bổ trợ';
          break;
        case 23:
          text = 'Khác';
          break;
      }
      return text;
    },
    changeType()
    {
      if (this.ticket.type_auto == 1) {
        this.show = true;
        return;
      }
      this.show = false;
    }
  }

}
</script>

<style>
#ticket-show .el-loading-spinner .circular {
    margin-top: 5px;
    height: 36px;
    width: 28px;
}

#ticket-show .ck-content .image-inline img, .ck-content .image-inline picture,
#ticket-show .ck-content .image img {
      height: 300px;
      width: 200px;
      object-fit: cover;
  }

  .closes-ticket-show-log::before{
    border: 1px solid #00000059;
    border-radius: 50px;
    font-size: 12px;
    padding: 1px;
    z-index: 1000;
  }

  .closes-ticket-show-log:hover{
   color: red;
  }
.bg-violet {
  background: #5E00B6;
}
</style>
